<p><b>Welcome to LN Court Services website!</b></p>
<br>
<p>The purpose of this application is to provide and track the current status of the courts that we gather information from.</p>
<p>Key Components:</p>
<pre>
      <b>1. Analytics</b>
          The main page provides a quick glance of the current counts of court services with their court stand and the work queue totals. The user can click the ‘Refresh’ button for updated information.         
      <b>2. Work Queue </b>       
          Listing of courts to contact in priority order.  Please refer to user guide for complete instructions.          
      <b>3. Query</b>         
          Provides ability to select court fields and rows based on criteria, such as state, and download to excel or csv for further review.  Use of shared queries is encouraged.         
      <b>4. Admin Functions  </b>     
          Individuals assigned to an Admin role have pages specific to managing the user interface, such as security, priority administration, unlocking court rows and drop-down value maintenance. 
</pre>
<br>
<p>To use the website, please select from menu items to proceed.</p>
<p>For additional information, please click Help</p>
<div class="col">
    <button class="btn btn-secondary" (click)="helpButtonHandle()">
      Help?
    </button>
</div>
<div *ngIf="isShown" class="row container-fluid"  id="divshow" >
    <ul>
      <h1>Frequently Asked Questions</h1>
      <pre>
        <b>What courts should I pick?</b>

          Please work the list as it appears, which is in priority order.  Priority 1 courts should be worked first. 

        <b>Why does the court have more than one row? </b>

          Some courts have multiple rows because there is one row for each workday (Monday, Tuesday, Wednesday, Thursday, Friday).  
          Hours & contact information may be different for each day.  

        <b>What happens when I click the check box next to a court?</b> 

          Court is locked for you to work on that court. You may click on more than one.  The values for the court will appear at the bottom of the screen.  
          Change values as advised in training.   

        <b>What occurs when I click “Save”?</b> 

          If you have made any changes, the court priority will be updated to ‘Priority 4’ and information will be stored in the database.  
          The court row will fall to the bottom of the Priority 4 section 
          (unless the priority is changed to something other than Priority 4.)  

        <b>Why would I want to click “Save as draft”?</b> 

          When calling a court, you may need to have a brief pause while you get more information.  
          Clicking “Save for Later” will hold on to your changes, but not commit them or update the Priority.  
          This saves the fields that were corrected without being committed to the database. 
          An example of using this is when you are about to be timed out or need to step away. The data in the row will be held. 

        <b>I’m going to be out, what happens to my records that were locked?</b> 

          An LN Admin will be able to unlock these so that another team member may complete. 

        <b>What do I do if I need a query deleted? </b> 

          Reach out to your LN Admin for advisement. 
      </pre>

      <br>
      <p>Click Help? button again to dismiss...</p>
      <br>
    </ul>

</div>