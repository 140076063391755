import { Component, OnInit,OnDestroy } from "@angular/core";
//import { ChartOptions, ChartType, ChartDataset } from "chart.js";
//import { Label } from "ng2-charts";
import { DataService } from "src/app/core/shared/data.service";


@Component({
  selector: "app-dashboard",
  templateUrl: "./dashboard.component.html",
  styleUrls: ["./dashboard.component.css"],
})

export class DashboardComponent implements OnInit,OnDestroy {

  isShown: boolean = false ; // hidden by default

  constructor(private DataService: DataService) {}

  ngOnInit() {
    this.DataService.setPageTitle("Home");
    let url = window.location.href.replace(window.location.host, '').replace('http://', '');
    var reloadVal = localStorage.getItem('reloaded');
    if(reloadVal && reloadVal=='0')
    {
      window.location.reload();
      localStorage.setItem('reloaded','1');
    }
    else
    {
      //console.log('no reload needed');
    }

  }//end of fx

  ngOnDestroy() {

  }//end of fx

  helpButtonHandle(): void
  {
    this.isShown = ! this.isShown;
  }//end of fx
}
